/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.add-column-wrapper[data-v-3f34ebf1] {
  padding: 2rem 4rem;
}
label[data-v-3f34ebf1] {
  display: block;
}
.manager-date[data-v-3f34ebf1],
.manager-mv-cf[data-v-3f34ebf1] {
  width: 100%;
  margin-bottom: 1rem;
}
.manager-date select[data-v-3f34ebf1],
.manager-mv-cf select[data-v-3f34ebf1] {
  width: 100%;
  padding: 0.8rem;
  cursor: pointer;
}
.manager-date select[data-v-3f34ebf1]:hover,
.manager-mv-cf select[data-v-3f34ebf1]:hover {
  border-color: #409aff;
}
.popup-error[data-v-3f34ebf1] {
  color: red;
  font-weight: 1.4rem;
}