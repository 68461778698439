/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.data-input[data-v-5600f0ff] {
  position: relative;
  box-sizing: border-box;
  padding: 0 2px;
}
.data-input[data-v-5600f0ff]  .text-input-wrapper {
  align-items: center;
  height: 100%;
  width: 160px;
  margin-left: auto;
}
.data-input .warning-message[data-v-5600f0ff] {
  position: absolute;
  z-index: 1;
  background: white;
  border: solid 1px #e93f6f;
  border-radius: 5px;
  color: #e93f6f;
  font-size: 10px;
  padding: 4px;
  text-align: center;
  top: -40px;
  font-weight: bold;
  box-shadow: 0px 0px 5px 0px rgba(68, 68, 68, 0.26);
}
.data-input .warning-message[data-v-5600f0ff]:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #e93f6f;
  bottom: -5px;
  right: 10px;
}
.total-value[data-v-5600f0ff],
td[data-v-5600f0ff] {
  font-weight: 600;
}
.column-date[data-v-5600f0ff] {
  position: relative;
}
.column-date .delete-icon[data-v-5600f0ff] {
  position: absolute;
  right: 1rem;
  vertical-align: middle;
  font-size: 1.3rem;
  color: #e93f6f;
  opacity: 0.4;
  cursor: pointer;
}
.column-date .delete-icon[data-v-5600f0ff]:hover {
  opacity: 1;
}
.column-header[data-v-5600f0ff] {
  white-space: nowrap;
}
.column-header .currency-indicator[data-v-5600f0ff] {
  font-size: 13px;
  color: #bdbdbd;
  font-weight: bold;
}